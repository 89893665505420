body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

button {
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.App {
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, 0);
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	gap: 8px;
	padding: 5px;
}

.btn-lg {
	background-color: #fff;
	width: 140px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.btn-sm {
	background-color: #8d99ae;
	border-radius: 50%;
	font-size: 12px;
	padding: 2px;
}

.tree {
	width: 100%;
	height: auto;
	text-align: center;
	ul {
		padding-top: 20px;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			border-left: 1px solid #ccc;
			width: 0;
			height: 20px;
		}
	}
	li {
		display: inline-table;
		width: max-content;
		text-align: center;
		list-style-type: none;
		position: relative;
		padding: 10px;
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 50%;
			border-top: 1px solid #ccc;
			width: 51%;
			height: 10px;
		}
		&::after {
			right: auto;
			left: 50%;
			border-left: 1px solid #ccc;
		}
		&:only-child::after,
		&:only-child::before {
			display: none;
		}
		&:only-child {
			padding-top: 0;
		}
		&:first-child::before,
		&:last-child::after {
			border: none;
		}
		&:last-child::before {
			border-right: 1px solid #ccc;
			border-radius: 0 5px 0 0;
		}
		&:first-child::after {
			border-radius: 5px 0 0 0;
		}
	}
	.main {
		&::before {
			display: none;
		}
	}
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.1);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	.modal {
		width: 100%;
		max-width: 400px;
		height: 150px;
		background-color: #fff;
		position: relative;
		padding: 10px;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border: 1px solid #000;
		text-align: center;
		> div {
			padding-top: 1rem;
			display: flex;
			justify-content: center;
			gap: 1rem;
		}
		.btn-lg {
			background-color: #8d99ae;
		}
		.btn-exit {
			position: absolute;
			top: 5px;
			right: 5px;
			font-size: 1.5rem;
		}
	}
}

.input {
	width: 140px;
	height: 40px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 0 10px;		
	font-size: 1.1rem;
	&:focus {
		outline: none;
	}
}
